@use "sass:color";
@use "../base/breakpoints";

$colors: (
  "blue": oklch(55% 0.2308 256.91),
  "brown": oklch(60% 0.0941 72.67),
  "cyan": oklch(60% 0.1497 234.48),
  "green": oklch(70% 0.2155 150),
  "indigo": oklch(60% 0.191 278.34),
  "orange": oklch(70% 0.2001 50.74),
  "pink": oklch(60% 0.2601 12.28),
  "purple": oklch(60% 0.2308 314.6),
  "red": oklch(60% 0.2671 30),
  "yellow": oklch(80% 0.17 76),
  "teal": oklch(60% 0.1122 216.72),
  "white": #fff,
  "black": oklch(from var(--accent-color) 2% 0.075 h),
);
$semantics: (
  "accent": var(--blue),
  "text": var(--black),
  "error": var(--red),
  "warning": var(--orange),
  "success": var(--green),
  "destructive": var(--red),
  "build": var(--green),
  "danger": var(--red),
  "info": var(--blue),
);

:root,
.app-theme-picker__picker[data-theme="none"] {
  // Font related properties
  --font-family-sans: system-ui, "Segoe UI", roboto, "Noto Sans", oxygen,
    ubuntu, cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-mono: ui-monospace, sfmono-regular, sf mono, jetbrainsmono,
    consolas, monospace;
  --font-size-base: 1rem; // 16px
  --font-size-sm: 0.875rem; // 14px
  --font-size-xs: 0.75rem; // 12px
  --font-size-monospace: 1em;
  --font-bold-weight: 450;

  // Line height
  --line-height-base: 1.5;
  --line-height-heading: 1.2;

  // Color palette
  --very-light-grey: #f8f8f8;
  --light-grey: hsl(240 20% 96.5%);
  --medium-grey: #9ba7af;
  --dark-grey: #4d545d;

  // branding
  --secondary: oklch(from var(--black) 60% c h);
  --focus-input-border: var(--accent-color);
  --focus-input-glow: color-mix(in sRGB, var(--accent-color) 15%, transparent);

  // State colors
  --primary-hover: var(--accent-color);
  --primary-active: var(--accent-color);

  // Status icon colors
  --weather-icon-color: var(--accent-color);
  --unstable-build-icon-color: var(--orange);

  // Background colors
  --background: var(--white);

  // Header
  --brand-link-color: var(--secondary);
  --header-link-color: var(--white);
  --header-bg-classic: var(--black);
  --header-link-bg-classic-hover: #404040;
  --header-link-bg-classic-active: #404040;

  // Breadcrumbs bar
  --breadcrumbs-bar-background: oklch(
    from var(--text-color) 96.8% 0.005 h / 0.8
  );

  // Alert call outs
  --alert-success-text-color: var(--success-color);
  --alert-success-bg-color: color-mix(
    in sRGB,
    var(--success-color) 10%,
    transparent
  );
  --alert-success-border-color: color-mix(
    in sRGB,
    var(--success-color) 5%,
    transparent
  );
  --alert-info-text-color: var(--blue);
  --alert-info-bg-color: color-mix(in sRGB, var(--blue) 10%, transparent);
  --alert-info-border-color: color-mix(in sRGB, var(--blue) 5%, transparent);
  --alert-warning-text-color: color-mix(
    in sRGB,
    var(--warning-color) 80%,
    var(--text-color)
  );
  --alert-warning-bg-color: color-mix(
    in sRGB,
    var(--warning-color) 10%,
    transparent
  );
  --alert-warning-border-color: color-mix(
    in sRGB,
    var(--warning-color) 5%,
    transparent
  );
  --alert-danger-text-color: var(--error-color);
  --alert-danger-bg-color: color-mix(
    in sRGB,
    var(--error-color) 10%,
    transparent
  );
  --alert-danger-border-color: color-mix(
    in sRGB,
    var(--error-color) 5%,
    transparent
  );

  // Typography
  --text-color-secondary: var(--secondary);

  // Table
  --table-background: oklch(from var(--text-color-secondary) l c h / 0.1);
  --table-header-foreground: var(--text-color);
  --table-body-background: var(--background);
  --table-body-foreground: var(--text-color);
  --table-border-radius: 0.75rem;
  --table-row-border-radius: 0.3125rem;

  // Deprecated
  --even-row-color: var(--very-light-grey);
  --bigtable-border-width: var(--pane-border-width);
  --bigtable-header-bg: var(--dark-grey);
  --bigtable-header-font-weight: bold; // Does specifying this make sense
  --bigtable-header-text-color: var(--white);
  --bigtable-row-border-color: var(--medium-grey);
  --bigtable-cell-padding-x: 0.75rem;
  --bigtable-cell-padding-y: 0.5rem;
  --table-parameters-bg--hover: var(--light-grey);
  --table-striped-bg--hover: var(--light-grey);

  // Link
  --link-color: var(--accent-color);
  --link-visited-color: var(--link-color);
  --link-color--hover: var(--link-color);
  --link-color--active: var(--link-color);
  --link-text-decoration: none;
  --link-text-decoration--hover: underline;
  --link-text-decoration--active: underline;
  --link-font-weight: var(--font-bold-weight);

  // Command Palette
  --command-palette-results-backdrop-filter: contrast(0.7) brightness(1.5)
    saturate(1.4) blur(20px);
  --command-palette-inset-shadow: inset 0 0 2px 2px rgb(255 255 255 / 0.1);

  ::backdrop {
    --command-palette-backdrop-background: radial-gradient(
      farthest-corner at 50% 30vh,
      rgb(0 0 0 / 0.3),
      rgb(0 0 0 / 0.1)
    );
  }

  // Tooltips
  --tooltip-backdrop-filter: saturate(2) blur(20px);
  --tooltip-color: var(--text-color);
  --tooltip-box-shadow: 0 0 8px 2px rgb(0 0 30 / 0.05),
    0 0 1px 1px rgb(0 0 20 / 0.025), 0 10px 20px rgb(0 0 20 / 0.15);

  // Dropdowns
  --dropdown-backdrop-filter: saturate(1.5) blur(20px);
  --dropdown-box-shadow: 0 10px 30px rgb(0 0 20 / 0.2),
    0 2px 10px rgb(0 0 20 / 0.05), inset 0 -1px 2px rgb(255 255 255 / 0.025);

  // Dialogs
  ::backdrop {
    --dialog-backdrop-background: hsl(240 10% 20% / 0.8);
  }

  --dialog-box-shadow: 0 10px 40px rgb(0 0 20 / 0.15),
    0 2px 15px rgb(0 0 20 / 0.05), inset 0 0 2px 2px rgb(255 255 255 / 0.025);

  // Dark link
  --link-dark-color: var(--text-color);
  --link-dark-visited-color: var(--link-dark-color);
  --link-dark-color--hover: var(--primary-hover);
  --link-dark-color--active: var(--primary-active);
  --link-dark-text-decoration: none;
  --link-dark-text-decoration--hover: underline;
  --link-dark-text-decoration--active: underline;
  --link-dark-font-weight: var(--font-bold-weight);

  // Pane
  --pane-border-width: 1px;
  --pane-header-text-color: var(--text-color);
  --pane-header-bg: var(--light-grey);
  --pane-header-border-color: var(--light-grey);
  --pane-header-font-weight: bold;
  --pane-border-color: var(--light-grey);
  --pane-text-color: var(--text-color);
  --pane-link-color: black;
  --pane-link-color--visited: black;

  // Cards
  --card-background: var(--background);
  --card-background--hover: transparent;
  --card-background--active: transparent;
  --card-border-color: oklch(from var(--text-color-secondary) l c h / 0.15);
  --card-border-color--hover: oklch(
    from var(--text-color-secondary) l c h / 0.3
  );
  --card-border-color--active: oklch(
    from var(--text-color-secondary) l c h / 0.5
  );
  --card-border-width: 2px;

  // Tab bar
  --tabs-background: oklch(from var(--text-color-secondary) l c h / 0.1);
  --tabs-item-background: transparent;
  --tabs-item-foreground: var(--text-color);
  --tabs-item-background--hover: rgb(0 0 0 / 0.05);
  --tabs-item-foreground--hover: var(--text-color);
  --tabs-item-background--active: rgb(0 0 0 / 0.1);
  --tabs-item-foreground--active: var(--text-color);
  --tabs-item-background--selected: white;
  --tabs-item-foreground--selected: var(--link-color);
  --tabs-border-radius: calc((10px + 34px) / 2);

  // Side panel
  --side-panel-width: 340px;
  --panel-header-bg-color: var(--light-grey);
  --panel-border-color: var(--light-grey);

  // Form
  --section-padding: 1.625rem;
  --input-color: color-mix(
    in sRGB,
    var(--text-color-secondary) 1.5%,
    var(--background)
  );
  --input-border: color-mix(
    in sRGB,
    var(--text-color-secondary) 25%,
    transparent
  );
  --input-border-hover: color-mix(
    in sRGB,
    var(--text-color-secondary) 50%,
    transparent
  );
  --input-hidden-password-bg-color: #f9f9f9;
  --form-item-max-width: min(65vw, 1600px);
  --form-item-max-width--medium: min(50vw, 1400px);
  --form-item-max-width--small: min(35vw, 1200px);

  @media screen and (max-width: breakpoints.$tablet-breakpoint) {
    --section-padding: 1.25rem;
    --form-item-max-width: 100%;
    --form-item-max-width--medium: 100%;
    --form-item-max-width--small: 100%;
  }

  --form-label-font-weight: var(--font-bold-weight);
  --form-input-padding: 0.625rem;
  --form-input-border-radius: 0.625rem;
  --form-input-glow: 0 0 0 10px transparent;
  --form-input-glow--focus: 0 0 0 5px var(--focus-input-glow);
  --pre-background: rgb(0 0 0 / 0.05);
  --pre-color: var(--text-color);
  --selection-color: oklch(from var(--accent-color) l c h / 0.2);

  // Animations
  --standard-transition: 0.3s ease;
  --elastic-transition: 0.3s cubic-bezier(0, 0.68, 0.5, 1.5);

  // Plugin manager
  --plugin-manager-bg-color-already-upgraded: var(--light-grey);

  // Default button
  --button-background: oklch(from var(--text-color-secondary) l c h / 0.075);
  --button-background--hover: oklch(
    from var(--text-color-secondary) l c h / 0.125
  );
  --button-background--active: oklch(
    from var(--text-color-secondary) l c h / 0.175
  );
  --button-box-shadow--focus: oklch(
    from var(--text-color-secondary) l c h / 0.1
  );
  --button-color--primary: var(--background);

  // Variables for sidebar items, card items
  --item-background--hover: oklch(from var(--text-color-secondary) l c h / 0.1);
  --item-background--active: oklch(
    from var(--text-color-secondary) l c h / 0.15
  );
  --item-box-shadow--focus: oklch(from var(--text-color-secondary) l c h / 0.1);

  // Deprecated
  --primary: var(--accent-color); // Use var(--accent-color) instead
  --success: var(--green); // Use var(--success-color) instead
  --danger: var(--red); // Use var(--destructive-color) instead
  --warning: var(--orange); // Use var(--warning-color) instead

  // Colors
  @each $key, $value in $colors {
    --#{$key}: #{$value};

    @if $key != "black" and $key != "white" {
      --light-#{$key}: #{color.adjust($value, $lightness: 20%)};
      --dark-#{$key}: #{color.adjust($value, $lightness: -20%)};
    }
  }

  @each $key, $value in $semantics {
    --#{$key}-color: #{$value};
  }
}
