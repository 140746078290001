@use "../abstracts/mixins";
@use "../base/breakpoints";

$background-outset: 0.7rem;

#tasks,
.subtasks {
  display: flex;
  flex-direction: column;
  margin: var(--section-padding);
  gap: 0.125rem;

  @media (min-width: breakpoints.$tablet-breakpoint) {
    margin-right: calc($background-outset);
  }
}

.subtasks {
  margin-top: 5px;
  margin-bottom: 0;

  &:empty {
    display: none;
  }
}

#side-panel {
  .jenkins-app-bar {
    margin-top: var(--section-padding);
    margin-left: var(--section-padding);
    margin-right: var(--section-padding);
  }

  & > #tasks > .jenkins-search-container {
    margin-left: -$background-outset;
    margin-right: -$background-outset;
    margin-bottom: calc(var(--section-padding) / 2);

    .jenkins-search__icon {
      width: 2.8rem;
      aspect-ratio: unset;
    }

    .jenkins-search__input {
      padding-left: 2.8rem;
    }
  }
}

#side-panel .jenkins-search__results-container--visible .task-link {
  opacity: 0.3;
}

#tasks .task {
  margin: 0 calc($background-outset * -1);
}

#tasks .task .task-link {
  @include mixins.item;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55rem $background-outset;
  gap: 0.65rem;
  width: 100%;
  cursor: pointer;
  font-weight: normal !important;
  font-size: 0.875rem;
  color: var(--text-color) !important;
  background: transparent;
  outline: none;
  border: none;
  text-decoration: none;
  margin: 0;
  transition: opacity var(--standard-transition);

  .task-icon-link {
    display: inline-flex;

    svg,
    img {
      width: 1.375rem !important;
      height: 1.375rem !important;
      color: var(--text-color);

      * {
        transition: stroke-width var(--standard-transition);
      }
    }
  }

  .task-icon-badge {
    margin-left: auto;
  }

  .task-link-text {
    display: contents;
    word-break: break-word;
  }

  &--active {
    font-weight: 500 !important;
    cursor: default;

    svg * {
      stroke-width: 35px;
    }

    &::before {
      background-color: var(--item-background--active) !important;
    }

    &::after {
      box-shadow: none !important;
    }
  }
}
